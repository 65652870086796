.detail-figure {
	margin: 0;
	box-shadow: rgb(0 0 0 / 5%) 2px 2px 10px 2px;
}

.detail-title {
	margin: 28px 0;
	text-align: center;
	font-weight: 700;
}

.info-section-1 {
	box-shadow: rgb(0 0 0 / 5%) 2px 2px 10px 2px;
	padding: 24px 18px;
}
.info-section-1 .info-wrap {
	display: flex;
}

.info-section-1 .info-wrap .info-title {
	width: 80px;
	margin-right: 30px;
	font-weight: 700;
}

.section {
	margin-bottom: 50px;
}

.section img {
	width: 100%;
	object-fit: contain;
}

.section .info-section-title {
	font-weight: 700;
}

.section .info-section-desc p {
	margin-bottom: 8px;
}
