.title {
	margin-bottom: 40px;
}

.login-form-wrap > div {
	margin-bottom: 24px;
}

.join {
	/* float: right; */
	margin-top: 10px;
	font-weight: 700;
	cursor: pointer;
}
