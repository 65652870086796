.custom-popup p {
	font-size: 14px;
	margin-top: 10px;
}

.custom-popup p span {
	color: var(--pb-6);
	text-decoration: underline;
}

.custom-popup button {
	width: 120px;
	height: 44px;
	margin-top: 20px;
}
