@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('./fonts/woff2/Pretendard-Regular.woff2') format('woff2'),
		url('./fonts/woff/Pretendard-Regular.woff') format('woff');
}
@font-face {
	font-family: 'Pretendard';
	font-display: swap;
	font-weight: 700;
	src: local('Pretendard Bold'), url('./fonts/woff2/Pretendard-Bold.woff2') format('woff2'),
		url('./fonts/woff/Pretendard-Bold.woff') format('woff');
}

#root {
	font-family: 'Pretendard';
	color: var(--gray-8);
	font-size: 14px;
}
:root {
	font-family: 'Pretendard';
	color: var(--gray-8);
	font-size: 14px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
