ul.subTab {
	text-align: left;
	margin-bottom: 20px;
	border: none;
}

ul.subTab > li {
	cursor: pointer;
	margin-right: 4px;
	margin-left: 0;
}

ul.subTab .nav-link.active {
	border: none;
}

ul.subTab .nav-link.active {
	font-weight: bold;
	color: #212529;
	width: auto;
	font-size: 16px;
	border-bottom: 2px solid #212529;
	padding-bottom: 2px;
}

ul.subTab .nav-link {
	border: none;
	color: var(--gray-6);
}
