input.form-control {
	height: 52px;
}

input.form-control:focus {
	border-color: #000;
	box-shadow: none;
}

button.btn {
	height: 52px;
}

svg {
	vertical-align: initial !important;
}
