.main-image {
	max-width: 1024px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

h4 {
	margin-bottom: 24px;
	font-weight: 700;
}

.card-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
	margin-bottom: 80px;
	justify-content: space-between;
	min-height: 600px;
}

.card-wrap .card {
	width: 48%;
	margin-bottom: 24px;
}

.card-title {
	font-size: 15px;
	margin-bottom: 24px;
}

.info-wrap {
	display: flex;
	font-size: 13px;
}

.info-wrap .info-title {
	width: 20px;
}

.info-wrap p {
	margin-bottom: 8px;
}

.img-container-wrap {
	position: relative;
	margin-bottom: 200px;
}
.img-container-wrap .img-wrap {
	width: 60%;
}
.img-container-wrap .top_img {
	position: absolute;
	top: 68%;
	left: 40%;
}

.sub-section h4 {
	margin-bottom: 4px;
}
.sub-section .text-wrap {
	margin-bottom: 24px;
}

.slide-wrap {
	margin-bottom: 80px;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-wrapper {
	display: -webkit-inline-box !important;
}

.swiper-slide {
	text-align: center;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide .card {
	height: 200px;
	text-align: left;
}

.swiper-slide .card-title {
	text-align: right;
	padding-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid #727272;
}

.swiper-slide .card-text {
	overflow: scroll;
	height: 135px;
}

@media (max-width: 425px) {
	.card-wrap > div {
		display: block;
	}
	.card-wrap .card {
		width: 100%;
		margin-bottom: 24px;
	}
}
