.product-list-btn-wrap {
	display: flex;
	justify-content: space-between;
}

.product-list-btn-wrap .form-select {
	width: 120px;
	height: 52px;
	margin-right: 10px;
}

.product-list-btn-wrap > div {
	display: flex;
}

.product-list-table td {
	vertical-align: middle;
}

.basic-row {
	border-top: 1px solid #cbcbcb;
	min-height: 68px;
}
.basic-row:last-child {
	border-bottom: 1px solid #cbcbcb;
}

.basic-label,
.basic-form {
	padding-top: 12px;
	padding-bottom: 12px;
}

.basic-label {
	width: 140px !important;
	display: flex;
	text-align: end;
	align-items: center;
	justify-content: flex-end;
	background-color: #f1f1f1;
	font-weight: 700;
	font-size: 14px !important;
}

.detail-info-wrap h2 {
	font-weight: 700;
	margin-bottom: 12px;
}

.detail-info-wrap .detail-info-form-wrap {
	margin-bottom: 60px;
}

.detail-info-wrap .tox-tinymce {
	min-height: 800px;
}

.detail-info-wrap .save-btn {
	display: block;
	width: 320px;
	margin: 40px auto;
	font-size: 20px;
}

.question-detail-wrap {
	height: 100%;
	display: flex;
	align-items: center;
}
.question-detail-wrap p {
	margin-bottom: 0;
}
