#gajago-container {
	max-width: 768px;
	min-width: 360px;
	margin: 0 auto;
	padding: 30px 20px;
}

#gajago-admin-container {
	padding: 80px;
}
