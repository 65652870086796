#gajago-nav {
	padding: 5px 10px;
	background-color: #fff !important;
	border-bottom: 1px solid #dcdcdc;
}
#gajago-nav .navbar-toggler {
	border: none;
}

#gajago-nav .navbar-toggler:focus {
	box-shadow: none;
}
.admin-wrap {
	margin-top: 30px;
}
