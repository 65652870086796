.review-masonry-grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: -30px;
	width: auto;
}
.review-masonry-grid_column {
	padding-left: 30px;
	background-clip: padding-box;
	margin-right: -3px;
}
.masonry-grid_column > div {
	background-color: #fafafa;
	margin-bottom: 30px;
}
