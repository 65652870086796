.title {
	margin-bottom: 40px;
}

.join-form-wrap > div {
	margin-bottom: 24px;
}

.join-form-wrap .gender-wrap {
	height: 52px;
	display: flex;
	align-items: center;
}

.empty-img {
	width: 180px;
	height: 180px;
	background-color: #e2e2e2;
	border-radius: 10px;
	margin-right: 10px;
}

.join-form-wrap .profile-wrap {
	display: flex;
	align-items: flex-end;
}

.join-form-wrap .profile-wrap Figure {
	margin-bottom: 0;
	margin-right: 10px;
}

.join-form-wrap .profile-wrap .figure-img {
	max-height: 180px;
	object-fit: contain;
	border-radius: 10px;
	border: 1px solid #e2e2e2;
	margin-bottom: 0;
}

.deactivate-wrap {
	margin-top: 30px;
	display: flex;
	justify-content: end;
}

.deactivate-wrap .deactivate {
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
}

.date-picker {
	width: 60%;
	height: 52px;
	padding: 0 10px;
	border-radius: 0.375rem;
	border: 1px solid #ced4da;
}
