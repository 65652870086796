.customPagination {
	justify-content: center;
	margin-bottom: 20px;
	margin-bottom: 40px;
}

.customPagination .page-item:not(:last-child) {
	margin-right: 8px;
}

.customPagination .page-item .page-link {
	color: var(--gray-5);
	font-size: 16px;
}

.customPagination .page-item.active .page-link {
	background-color: var(--pb-1);
	color: var(--pb-6);
}

.customPagination .page-item:hover .page-link {
	background-color: var(--gray-2);
	color: var(--gray-5);
}

.customPagination .page-item.disabled .page-link {
	color: var(--gray-5);
}

.customPagination .page-item.disabled:hover .page-link {
	background-color: transparent;
}
